import { useState, useContext } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// Icons
import { MoreVert, PictureInPictureAlt } from '@mui/icons-material';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { openConversationOnFloatingWindow } from 'redux/features/messagesSlice/messagesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const MoreActions = () => {
  const dispatch = useAppDispatch();

  const { selectMessagesMode, setSelectMessagesMode, setSelectedMessages } =
    useContext(conversationsContext);
  const { infoMessagesHandler } = useContext(messagesContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleSelectMessagesMode = () => {
    setSelectMessagesMode(!selectMessagesMode);
    setSelectedMessages([]);
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFloatingWindow = async () => {
    const response = await dispatch(openConversationOnFloatingWindow());

    if (response.payload?.response?.data?.message === 'Already exists') {
      // Dispatch message to listen in footer project
      window.postMessage(
        { type: 'openChatWindow', data: response.payload.chatWindowData },
        '*'
      );
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="small" sx={{ color: 'text.disabled' }}>
        <MoreVert />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openMenu}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
          },
        }}
      >
        <MenuItem key={1} onClick={handleFloatingWindow}>
          <ListItemIcon>
            <PictureInPictureAlt
              fontSize="small"
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          </ListItemIcon>
          <ListItemText>
            {t('conversations.actions.openFloatingWindow')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreActions;
