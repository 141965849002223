//Translate
import { useTranslation } from 'react-i18next';
import { Box, Chip, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { IMessage, MessageInfo } from '@trii/types/dist/Common/Messages';
import { useContext, useEffect, useState } from 'react';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';

const InfoType = ({ data, message }: { data: MessageInfo; message: IMessage }) => {
  const { t } = useTranslation();

  const { timestamp } = message;
  const dataMessageInfo = data.data;

  const conversationSelected = useSelector(selectConversationSelected);

  const dateHour = moment(timestamp).format('HH:mm');

  const [label, setLabel] = useState<string>('');

  const getLabel = () => {
    if (['201', '202', '203', '204', '205', '206', '207'].includes(data.code)) {
      return `${dateHour} - ${t(`chatCodes.${data.code}`, dataMessageInfo)}`;
    }
  };

  useEffect(() => {
    setLabel(getLabel());
  }, []);

  return (
    <Box>
      <Divider>
        <Chip
          label={label}
          size="small"
          sx={{
            color: 'primary.contrastText',
            backgroundColor: '#92a4ad',
            border: '1px solid',
            borderColor:
              message.conversationId === conversationSelected?.id
                ? //@ts-ignore
                  (theme) => theme.palette.warning.light
                : 'transparent',
          }}
          icon={<InfoIcon style={{ color: 'primary.contrastText' }} />}
        />
      </Divider>
    </Box>
  );
};

export default InfoType;
