import { useState, useEffect, useCallback } from 'react';

type ChatsOrderState = {
  orderingByLastMessageDate: boolean;
  orderingByChatName: boolean;
  isLastMessageAscending: boolean;
  isChatNameAscending: boolean;
};

type UseChatsOrderReturn = {
  state: ChatsOrderState;
  handleOrderByLastMessage: () => void;
  handleOrderByChatName: () => void;
};

export const useChatsOrder = (): UseChatsOrderReturn => {
  const [orderingByLastMessageDate, setOrderingByLastMessageDate] =
    useState<boolean>(true);
  const [orderingByChatName, setOrderingByChatName] = useState<boolean>(false);
  const [isLastMessageAscending, setIsLastMessageAscending] =
    useState<boolean>(false);
  const [isChatNameAscending, setIsChatNameAscending] = useState<boolean>(false);

  // Load initial state from localStorage
  useEffect(() => {
    const storedIsLastMessageAscending = localStorage.getItem(
      'isLastMessageAscending'
    );
    const storedIsChatNameAscending = localStorage.getItem('isChatNameAscending');
    const storedOrderingByLastMessageDate = localStorage.getItem(
      'orderingByLastMessageDate'
    );
    const storedOrderingByChatName = localStorage.getItem('orderingByChatName');

    if (storedIsLastMessageAscending) {
      setIsLastMessageAscending(JSON.parse(storedIsLastMessageAscending));
    }
    if (storedIsChatNameAscending) {
      setIsChatNameAscending(JSON.parse(storedIsChatNameAscending));
    }
    if (storedOrderingByLastMessageDate) {
      setOrderingByLastMessageDate(storedOrderingByLastMessageDate === 'true');
    }
    if (storedOrderingByChatName) {
      setOrderingByChatName(storedOrderingByChatName === 'true');
    }
  }, []);

  const handleOrderByLastMessage = useCallback(() => {
    setIsChatNameAscending(false);
    setOrderingByLastMessageDate(true);
    setOrderingByChatName(false);

    if (orderingByLastMessageDate) {
      const newValue = !isLastMessageAscending;
      setIsLastMessageAscending(newValue);
      localStorage.setItem('isLastMessageAscending', JSON.stringify(newValue));
    }

    localStorage.setItem('isChatNameAscending', JSON.stringify(false));
    localStorage.setItem('orderingByLastMessageDate', JSON.stringify(true));
    localStorage.setItem('orderingByChatName', JSON.stringify(false));
  }, [orderingByLastMessageDate, isLastMessageAscending]);

  const handleOrderByChatName = useCallback(() => {
    setOrderingByLastMessageDate(false);
    setOrderingByChatName(true);
    setIsLastMessageAscending(false);

    if (orderingByChatName) {
      const newValue = !isChatNameAscending;
      setIsChatNameAscending(newValue);
      localStorage.setItem('isChatNameAscending', JSON.stringify(newValue));
    }

    localStorage.setItem('isLastMessageAscending', JSON.stringify(false));
    localStorage.setItem('orderingByLastMessageDate', JSON.stringify(false));
    localStorage.setItem('orderingByChatName', JSON.stringify(true));
  }, [orderingByChatName, isChatNameAscending]);

  const state: ChatsOrderState = {
    orderingByLastMessageDate,
    orderingByChatName,
    isLastMessageAscending,
    isChatNameAscending,
  };

  return {
    state,
    handleOrderByLastMessage,
    handleOrderByChatName,
  };
};
