import { useContext, useEffect, useState } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Translations
import { useTranslation } from 'react-i18next';
// Icons
import { Close } from '@mui/icons-material';
// Components/ui
import { Box, IconButton, Modal, Slide, Typography } from '@mui/material';
// Components
import {
  AddComment,
  AddParticipant,
  CreateConversation,
  Label,
  MessageStatus,
  NewInternalChat,
  ForwardMessage,
  BotAnswer,
} from './components';
import AddForm from './components/AddForm';
import SendLocation from './components/SendLocation';

const ModalList = () => {
  const { t } = useTranslation();
  const { openModalList, setOpenModalList, modalTitle, getUsers, getGroups } =
    useContext(conversationsContext);
  const [width, setWidth] = useState('30rem');

  const handleClose = () => {
    setOpenModalList(false);
  };

  useEffect(() => {
    if (modalTitle === t('conversations.createConversation.title')) {
      setWidth('80rem');
      getUsers();
      getGroups();
    } else {
      setWidth('30rem');
    }
  }, [modalTitle]);

  return (
    <Modal
      open={openModalList}
      onClose={() => handleClose()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        marginTop: '5rem',
        background: 'transparent',
        '& .MuiBackdrop-root': {
          background: 'transparent',
        },
      }}
    >
      <Slide direction="down" in={openModalList} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: width,
            maxHeight: '40rem',
            minHeight: '10rem',
            height: 'max-content',
            borderRadius: 2,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '3rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography variant="h6" color={(theme) => theme.palette.text.primary}>
              {modalTitle}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Box>
          {modalTitle === t('conversations.actions.label') && <Label />}
          {modalTitle === t('conversations.message.actions.addComment') && (
            <AddComment />
          )}
          {modalTitle === t('conversations.message.actions.forward') && (
            <ForwardMessage />
          )}
          {modalTitle === t('conversations.message.status.title') && (
            <MessageStatus />
          )}
          {modalTitle === t('conversations.createConversation.title') && (
            <CreateConversation />
          )}
          {modalTitle === t('conversations.internalChat.newInternalChat') && (
            <NewInternalChat />
          )}
          {modalTitle === t('conversations.internalChat.addParticipant') && (
            <AddParticipant />
          )}
          {modalTitle === t('conversations.message.actions.selectForm') && (
            <AddForm />
          )}
          {modalTitle === t('conversations.message.actions.sendLocation') && (
            <SendLocation />
          )}
          {modalTitle === t('conversations.botAnswers') && <BotAnswer />}
        </Box>
      </Slide>
    </Modal>
  );
};

export default ModalList;
