import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface Props {
  text: string;
}

// Styled Components
const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '2.5rem',
  overflow: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  position: 'relative',
}));

const AbsoluteBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '0%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1), // Usar el sistema de espaciado de MUI
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '0.8rem',
}));

const Text = ({ text }: Props) => {
  return (
    <TextContainer>
      <AbsoluteBox>
        <StyledTypography>{text}</StyledTypography>
      </AbsoluteBox>
    </TextContainer>
  );
};

export default Text;
