import { useState, useContext, useEffect } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box,
} from '@mui/material';
// Icons
import { Sort, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

const OrderBy = () => {
  const { t } = useTranslation();

  const {
    orderingByLastMessageDate,
    orderingByChatName,
    isLastMessageAscending,
    isChatNameAscending,
    handleOrderByLastMessage,
    handleOrderByChatName,
  } = useContext(conversationsContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openOrderBy = Boolean(anchorEl);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        height: '100%',
      }}
    >
      <IconButton onClick={handleOpen} size="small" sx={{ color: 'text.disabled' }}>
        <Sort />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openOrderBy}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
          },
        }}
      >
        <MenuItem key={1} onClick={handleOrderByChatName}>
          <ListItemIcon sx={{ color: 'text.disabled' }}>
            {orderingByChatName &&
              (isChatNameAscending ? (
                <Tooltip title={t('conversations.orderBy.ascending')}>
                  <KeyboardArrowUp fontSize="small" />
                </Tooltip>
              ) : (
                <Tooltip title={t('conversations.orderBy.descending')}>
                  <KeyboardArrowDown fontSize="small" />
                </Tooltip>
              ))}
          </ListItemIcon>
          <ListItemText>{t('conversations.orderBy.name')}</ListItemText>
        </MenuItem>
        <MenuItem key={2} onClick={() => handleOrderByLastMessage()}>
          <ListItemIcon sx={{ color: 'text.disabled' }}>
            {orderingByLastMessageDate &&
              (isLastMessageAscending ? (
                <Tooltip title={t('conversations.orderBy.ascending')}>
                  <KeyboardArrowUp fontSize="small" />
                </Tooltip>
              ) : (
                <Tooltip title={t('conversations.orderBy.descending')}>
                  <KeyboardArrowDown fontSize="small" />
                </Tooltip>
              ))}
          </ListItemIcon>
          <ListItemText>{t('conversations.orderBy.lastMessage')}</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default OrderBy;
