// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { useContext, useEffect } from 'react';
import { Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import Conversations from './features/Views/Conversations';
import DateTimeProvider from 'context/DateTime/DateTimeProvider';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectUser, updateUser } from 'redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Types
import { IUserUpdate } from '@trii/types/dist/Common/EventsQueue';
import useNotificationSound from 'features/Views/Conversations/hooks/useNotificationSound';

const FAVICONS = {
  ACTIVE: '/favicon/TriiNotification.svg',
  ORIGINAL: '/favicon.ico',
};

const Routes = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  const { subscribeEvent, unsubscribeEvent, socketConnection } =
    useContext(socketContext);

  const notificationSound = useNotificationSound();

  useEffect(() => {
    const handleUserUpdate = (updatedUserData: IUserUpdate) => {
      dispatch(updateUser(updatedUserData));

      if (updatedUserData.userId === user.uid) {
        localStorage.setItem('userStatus', JSON.stringify(updatedUserData.status));
      }
    };

    if (socketConnection) {
      subscribeEvent('user_update', handleUserUpdate);
    }

    return () => {
      unsubscribeEvent('user_update');
    };
  }, [socketConnection]);

  // Handle favicon changes and notifications
  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    const originalFavicon = favicon?.href;

    const handleInactiveNotification = () => {
      if (document.hidden) {
        favicon.href = FAVICONS.ACTIVE;
        notificationSound.playNotification();
      }
    };

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        favicon.href = originalFavicon;
      }
    });

    if (socketConnection) {
      subscribeEvent('conversation_update', handleInactiveNotification);
    }

    return () => {
      unsubscribeEvent('conversation_update');

      document.removeEventListener('visibilitychange', () => {
        favicon.href = originalFavicon;
      });
    };
  }, [socketConnection]);

  useEffect(() => {
    navigate('/a/chat/conversations');
  }, [navigate]);

  return (
    <DateTimeProvider>
      <MainLayout>
        <ReactRoutes>
          <Route
            path="/a/chat/conversations/:contactId?"
            element={<Conversations />}
          />
        </ReactRoutes>
      </MainLayout>
    </DateTimeProvider>
  );
};

export default Routes;
