import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IConversation } from '@trii/types/dist/Conversations';
import { RootState } from '../../store';
import { ConversationsState } from './types/ConversationsState';
import { ModifyConversationLabelData } from './types/ModifyConversationLabelData';
import { IGroup } from '@trii/types/dist/Conversations/Groups/Group';
import { CreateConversation } from 'features/Views/Conversations/context/ConversationsProvider/types/CreateConversation';
import { IContact } from '@trii/types/dist/Contacts';
import { Chat } from './types/Chat';
import { UpdateChat } from './types/UpdateChat';
import { OpenInFooter } from './types/OpenInFooter';
import { ConversationsOfContact } from './types/ConversationsOfContact';
// Service
import conversationsService from './conversationsService';
import contactInfoService from '../contactInfoSlice/contactInfoService';
// Db
import { ChatRequestFilter } from './types/ChatRequestFilter';
import { dbWorker } from 'db/db';

const initialState: ConversationsState = {
  conversationSelected: null,
  newEvents: false,
  loadingConversation: false,

  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
    update: 'idle',
    pin: 'idle',
    in: 'idle',
    out: 'idle',
    transfer: 'idle',
    modify: 'idle',
    finalize: 'idle',
    groups: 'idle',
    channels: 'idle',
    openInFooter: 'idle',
    contactInfo: 'idle',
    chat: 'idle',
    updateChat: 'idle',
    conversationsOfContact: 'idle',
    unarchiveChat: 'idle',
    archiveChat: 'idle',
  },
  groups: [],
  channels: [],
  contactInfo: null,
  chat: null,
  chats: [],
  conversationsOfContact: [],
};

export const readConversation = createAsyncThunk(
  'conversations/readConversation',
  async (
    data: { conversationId: string; type: 'internal' | 'external' },
    { dispatch }
  ) => {
    try {
      const { conversationId, type } = data;
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;

      const response = await conversationsService.readConversation(
        jwtToken,
        URL_CONVERSATIONS,
        conversationId,
        type
      );

      return response;
    } catch (error) {
      return error.response;
    }
  }
);

export const modifyConversationLabel = createAsyncThunk(
  'conversations/modifyConversationLabel',
  async (data: ModifyConversationLabelData, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.modifyLabel(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const fetchGroups = createAsyncThunk<IGroup[]>(
  'contacts/fetchGroups',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchGroups(
      jwtToken,
      URL_CONVERSATIONS
    );

    return response;
  }
);

export const fetchChannels = createAsyncThunk(
  'contacts/fetchChannels',
  async (channel: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchChannels(
      jwtToken,
      URL_CONVERSATIONS,
      channel
    );

    return response;
  }
);

export const fetchOpenInFooter = createAsyncThunk(
  'contacts/fetchOpenInFooter',
  async (data: OpenInFooter, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.fetchOpenInFooter(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const createConversation = createAsyncThunk(
  'conversations/createConversation',
  async (data: CreateConversation, { dispatch }) => {
    try {
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;
      const response = await conversationsService.createConversation(
        jwtToken,
        URL_CONVERSATIONS,
        data
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
);
export const getContactInfo = createAsyncThunk(
  'conversations/getContactInfo',
  async (userId: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await contactInfoService.fetchContactInfo(
      jwtToken,
      URL_CONTACTS,
      userId
    );

    return response;
  }
);

export const fetchNewChat = createAsyncThunk(
  'conversations/fetchNewChat',
  async (data: Chat, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchNewChat(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const fetchChats = createAsyncThunk(
  'conversations/fetchChats',
  async (filter: ChatRequestFilter, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchChats(
      jwtToken,
      URL_CONVERSATIONS,
      filter
    );

    return response;
  }
);

export const fetchUpdateChat = createAsyncThunk(
  'conversations/fetchUpdateChat',
  async (data: UpdateChat, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await conversationsService.fetchUpdateChat(
      jwtToken,
      URL_CONVERSATIONS,
      data
    );

    return response;
  }
);

export const unarchiveChat = createAsyncThunk(
  'conversations/unarchiveChat',
  async (conversationId: string, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await conversationsService.unarchiveChat(
      jwtToken,
      URL_CONVERSATIONS,
      conversationId
    );
    return response;
  }
);

export const archiveChat = createAsyncThunk(
  'conversations/archiveChat',
  async (conversationId: string, { dispatch }) => {
    try {
      const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
        .payload as InitRequestDataReturn;

      const response = await conversationsService.archiveChat(
        jwtToken,
        URL_CONVERSATIONS,
        conversationId
      );
      return response;
    } catch (error) {
      return error.response;
    }
  }
);

export const fetchConversationsOfContact = createAsyncThunk(
  'conversations/fetchConversationsOfContact',
  async (data: ConversationsOfContact, { dispatch }) => {
    const { jwtToken, URL_CONVERSATIONS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { contactId, status } = data;
    const response = await conversationsService.fetchConversationsOfContact(
      jwtToken,
      URL_CONVERSATIONS,
      contactId,
      status
    );

    return response;
  }
);

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setConversationSelected(state, action: PayloadAction<IConversation>) {
      console.log('Setting conversation selected to: ', action.payload);
      if (!action.payload) {
        state.conversationSelected = null;
      } else {
        state.conversationSelected = action.payload;
      }
    },
    setLoadingConversation(state, action: PayloadAction<boolean>) {
      state.loadingConversation = action.payload;
    },
    setChat(state, action: PayloadAction<IConversation>) {
      const chatIndex = state.chats?.findIndex(
        (chat) => chat.id === action.payload.id
      );

      if (chatIndex !== -1 && chatIndex !== undefined) {
        state.chats[chatIndex] = action.payload;

        state.chats = state.chats.sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      } else {
        state.chats.push(action.payload);
      }
    },
    setChats(state, action: PayloadAction<IConversation[]>) {
      let newChats = action.payload;

      state.chats = newChats;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(modifyConversationLabel.pending, (state) => {
        state.status.modify = 'loading';
      })
      .addCase(
        modifyConversationLabel.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.modify = 'succeeded';
        }
      )

      .addCase(unarchiveChat.pending, (state) => {
        state.status.unarchiveChat = 'loading';
      })
      .addCase(unarchiveChat.fulfilled, (state, action) => {
        state.status.unarchiveChat = 'succeeded';

        const updatedChat = action.payload;
        const chatIndex = state.chats.findIndex(
          (chat) => chat.id === updatedChat.id
        );

        if (chatIndex !== -1) {
          state.chats.splice(chatIndex, 1);
        }
      })
      .addCase(archiveChat.pending, (state) => {
        state.status.archiveChat = 'loading';
      })
      .addCase(archiveChat.fulfilled, (state, action) => {
        state.status.archiveChat = 'succeeded';

        const updatedChat = action.payload;
        const chatIndex = state.chats.findIndex(
          (chat) => chat.id === updatedChat.id
        );

        if (chatIndex !== -1) {
          state.chats.splice(chatIndex, 1);
        }
      })
      .addCase(fetchGroups.fulfilled, (state, action: PayloadAction<IGroup[]>) => {
        state.status.groups = 'succeeded';
        console.log('fetchGroups succeeded: ', action.payload);
        state.groups = action.payload;
      })
      .addCase(fetchChannels.pending, (state) => {
        state.status.channels = 'loading';
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.status.channels = 'rejected';
        console.log('fetchChannels rejected: ', action.payload);
      })
      .addCase(fetchChannels.fulfilled, (state, action: PayloadAction<IGroup[]>) => {
        state.status.channels = 'succeeded';
        console.log('fetchChannels succeeded: ', action.payload);
        // @ts-ignore
        state.channels = action.payload;
      })
      .addCase(fetchOpenInFooter.pending, (state) => {
        state.status.openInFooter = 'loading';
      })
      .addCase(fetchOpenInFooter.rejected, (state, action) => {
        state.status.openInFooter = 'rejected';
        console.log('fetchOpenInFooter rejected: ', action.payload);
      })
      .addCase(
        fetchOpenInFooter.fulfilled,
        (state, action: PayloadAction<IGroup[]>) => {
          state.status.openInFooter = 'succeeded';
          console.log('fetchOpenInFooter succeeded: ', action.payload);
        }
      )
      .addCase(createConversation.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.status.create = 'rejected';
        console.log('createConversation rejected: ', action.payload);
      })
      .addCase(
        createConversation.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.create = 'succeeded';
          console.log('createConversation succeeded: ', action.payload);
        }
      )
      .addCase(getContactInfo.pending, (state) => {
        state.contactInfo = null;
        state.status.contactInfo = 'loading';
      })
      .addCase(getContactInfo.rejected, (state, action) => {
        state.status.contactInfo = 'rejected';
        state.contactInfo = null;
        console.log('getContactInfo rejected: ', action.payload);
      })
      .addCase(
        getContactInfo.fulfilled,
        (state, action: PayloadAction<IContact>) => {
          state.status.contactInfo = 'succeeded';
          state.contactInfo = action.payload;
          console.log('getContactInfo succeeded: ', action.payload);
        }
      )
      .addCase(fetchNewChat.pending, (state) => {
        state.status.chat = 'loading';
      })
      .addCase(fetchNewChat.rejected, (state, action) => {
        state.status.chat = 'rejected';
        state.chat = null;
        console.log('fetchNewChat rejected: ', action.payload);
      })
      .addCase(
        fetchNewChat.fulfilled,
        (state, action: PayloadAction<IConversation>) => {
          state.status.chat = 'succeeded';
          state.chat = action.payload;
          console.log('fetchNewChat succeeded: ', action.payload);
        }
      )
      .addCase(fetchChats.pending, (state) => {
        state.status.chat = 'loading';
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.status.chat = 'rejected';
        console.log('fetchChats rejected: ', action.payload);
      })
      .addCase(
        fetchChats.fulfilled,
        (state, action: PayloadAction<IConversation[]>) => {
          let newChats = action.payload;

          // Sort the chats in descending order by the updatedAt attribute
          newChats = newChats.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );

          state.status.chat = 'succeeded';
          state.chats = newChats;

          dbWorker.postMessage({
            action: 'updateChats',
            data: newChats,
          });

          console.log('fetchChats succeeded: ', action.payload);
        }
      )
      .addCase(fetchUpdateChat.pending, (state) => {
        state.status.chat = 'loading';
      })
      .addCase(fetchUpdateChat.rejected, (state, action) => {
        state.status.chat = 'rejected';
        console.log('fetchUpdateChat rejected: ', action.payload);
      })
      .addCase(fetchUpdateChat.fulfilled, (state) => {
        state.status.chat = 'succeeded';
      })
      .addCase(fetchConversationsOfContact.pending, (state) => {
        state.status.conversationsOfContact = 'loading';
      })
      .addCase(fetchConversationsOfContact.rejected, (state, action) => {
        state.status.conversationsOfContact = 'rejected';
        console.log('fetchConversationsOfContact rejected: ', action.payload);
      })
      .addCase(
        fetchConversationsOfContact.fulfilled,
        (state, action: PayloadAction<IConversation[]>) => {
          state.status.conversationsOfContact = 'succeeded';
          state.conversationsOfContact = action.payload;
        }
      );
  },
});

export const { setConversationSelected, setChat, setChats, setLoadingConversation } =
  conversationsSlice.actions;

const conversationsState = (state: RootState) => state.Conversations;
export const selectConversationSelected = createSelector(
  conversationsState,
  (conversations) => conversations.conversationSelected
);
export const selectLoadingConversation = createSelector(
  conversationsState,
  (conversations) => conversations.loadingConversation
);
export const selectConversationsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.fetch
);
export const selectCreteConversationStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.create
);
export const selectConversationsPinStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.pin
);
export const selectConversationsInStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.in
);
export const selectConversationsOutStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.out
);
export const selectConversationsTransferStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.transfer
);
export const selectConversationsModifyStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.modify
);
export const selectConversationsFinalizeStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.finalize
);
export const selectGroups = createSelector(
  conversationsState,
  (conversations) => conversations.groups
);
export const selectGroupsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.groups
);
export const selectChannels = createSelector(
  conversationsState,
  (conversations) => conversations.channels
);
export const selectChannelsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.channels
);
export const selectOpenInFooterFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.openInFooter
);
export const selectContactInfo = createSelector(
  conversationsState,
  (conversations) => conversations.contactInfo
);
export const selectContactInfoFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.contactInfo
);

export const selectNewInternalChatFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.chat
);
export const selectChats = createSelector(
  conversationsState,
  (conversations) => conversations.chats
);
export const selectChatsFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.chat
);
export const selectUpdateChatFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.updateChat
);
export const selectChatUnarchiveStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.unarchiveChat
);
export const selectChatArchiveStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.archiveChat
);
export const selectConversationsOfContact = createSelector(
  conversationsState,
  (conversations) => conversations.conversationsOfContact
);
export const selectConversationsOfContactFetchStatus = createSelector(
  conversationsState,
  (conversations) => conversations.status.conversationsOfContact
);

export default conversationsSlice.reducer;
