import {
  ChangeEvent,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  getMessages,
  uploadMedia,
} from 'redux/features/messagesSlice/messagesSlice';
import {
  createConversation,
  fetchChannels,
  fetchGroups,
  fetchOpenInFooter,
  fetchNewChat,
  getContactInfo,
  modifyConversationLabel,
  fetchChats,
  selectConversationSelected,
  setConversationSelected,
  setChat,
  readConversation,
  selectLoadingConversation,
  setLoadingConversation,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { fetchEndings } from 'redux/features/endingSlice/endingSlice';
import { fetchLabels } from 'redux/features/labelSlice/labelSlice';
import {
  fetchContactInfo,
  fetchDeleteFile,
  fetchDeleteNote,
  fetchFiles,
  fetchNewFile,
  fetchNewNote,
  fetchNotes,
  fetchUpdateNote,
  resetContacts,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';
import { fetchUsers } from 'redux/features/userSlice/userSlice';
// Dexie
import db, { dbWorker } from 'db/db';
import { useLiveQuery } from 'dexie-react-hooks';
// Types
import { ContactNotes } from 'redux/features/contactInfoSlice/types/ContactNotes';
import { ConversationsContext } from './types/ConversationsContext';
import { ConversationsProviderProps } from './types/ConversationsProviderProps';
import { IChatMemeber, IConversation } from '@trii/types/dist/Conversations';
import { IMessage, MessageImage } from '@trii/types/dist/Common/Messages';
import { ImageIcon } from '../../../../../types/ImageIcon';
import { IBusiness, IContact, IContactInfo, IFile } from '@trii/types/dist/Contacts';
import { OpenInFooter } from 'redux/features/conversationsSlice/types/OpenInFooter';
import { ContactFile } from 'redux/features/contactInfoSlice/types/ContactFile';
import { ConversationsOfContact } from 'redux/features/conversationsSlice/types/ConversationsOfContact';
import { AssignedTo, CreateConversation } from './types/CreateConversation';
import { ModifyConversationLabelData } from 'redux/features/conversationsSlice/types/ModifyConversationLabelData';
import { Chat } from 'redux/features/conversationsSlice/types/Chat';
// Translations
import { useTranslation } from 'react-i18next';
// Functions
import getImage from 'functions/getImage';
// ID
import { v4 as uuidv4 } from 'uuid';
import { UploadURLParams } from 'redux/features/messagesSlice/types/UploadURLParams';
import { Media } from 'redux/features/messagesSlice/types/Media';
import { ChatRequestFilter } from 'redux/features/conversationsSlice/types/ChatRequestFilter';
import { ChatFilter } from '../../components/components/SidebarContactList/components/InternalChat/types/ChatFilter';
import chatFilters from '../../components/components/SidebarContactList/components/InternalChat/utils/chatFilters';
import { useChatsOrder } from 'hooks/useChatsOrder';

export const conversationsContext = createContext<ConversationsContext>({
  filterCondition: chatFilters[0],

  setFilterCondition: () => {},
  orderingByLastMessageDate: true,
  orderingByChatName: false,
  isLastMessageAscending: false,
  isChatNameAscending: false,
  handleOrderByLastMessage: () => {},
  handleOrderByChatName: () => {},
  handleSingleFileUpload: () => {},
  handleOpenForwardModal: () => {},
  handleEscapeConversation: () => {},
  conversations: [],
  openConversation: (conversation: IConversation) => {},
  selectMessagesMode: false,
  setSelectMessagesMode: () => {},
  selectedMessages: [],
  setSelectedMessages: (messages: IMessage[]) => {},
  openImageViewer: false,
  setOpenImageViewer: (boolean: boolean) => {},
  imageViewerSrc: [],
  setImageViewerSrc: (imageList: MessageImage[]) => {},
  currentIndex: 0,
  setCurrentIndex: (index: number) => {},
  openModalList: false,
  setOpenModalList: (boolean: boolean) => {},
  openActionContact: false,
  setOpenActionContact: (boolean: boolean) => {},
  conversationId: '',
  setConversationId: (conversationId: string) => {},
  modalTitle: '',
  setModalTitle: (title: string) => {},
  channel: null,
  setChannel: (channel: ImageIcon) => {},
  getImage: (type: number) => {
    return null;
  },
  isEmailMode: false,
  setIsEmailMode: (boolean: boolean) => {},
  getUserInfo: (userId: string) => {},
  getChannelList: (channel: string) => {},
  contactInfo: null,
  businessInfo: null,
  setContactInfo: (contactInfo: IContact) => {},
  setBusinessInfo: (businessInfo: IBusiness) => {},
  handleOpenInFooter: (data: OpenInFooter) => {},
  expandedExternal: true,
  setExpandedExternal: (boolean: boolean) => {},
  expandedInternal: false,
  setExpandedInternal: (boolean: boolean) => {},
  handleChangeAccordion: (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    state: boolean
  ) => {},
  getContact: (contactId: string) => {},
  getEndings: () => {},
  getUsers: () => {},
  getGroups: () => {},
  getLabels: () => {},
  // Internal Chat
  internalGroupInfo: [],
  setInternalGroupInfo: (internalGroupInfo: IChatMemeber[]) => {},
  setConversation: (data: CreateConversation) => {
    return new Promise(() => {});
  },
  originSelected: '',
  setOriginSelected: (originSelected: string) => {},
  destinationSelected: [],
  setDestinationSelected: (destinationSelected: string[]) => {},
  assignedTo: null,
  setAssignedTo: (assignedTo: AssignedTo) => {},
  contactSelected: null,
  setContactSelected: (contactSelected: IContactInfo) => {},
  handleAddLabel: (data: ModifyConversationLabelData) => {},
  // Internal Chat
  setInternalChat: (data: Chat) => {},
  getInternalChats: () => {},
  // Notes
  getNotes: (contactId: string) => {},
  setNewNote: (data: ContactNotes) => {},
  updateNote: (data: ContactNotes) => {},
  deleteNote: (data: ContactNotes) => {},
  // Files
  getFiles: (contactId: string) => {},
  filesToUpload: [],
  setFilesToUpload: (files: IFile[]) => {},
  handleUploadFile: (event: ChangeEvent<HTMLInputElement>) => {},
  setNewFile: (data: ContactFile) => {
    return new Promise(() => {});
  },
  deleteFile: (fileId: string) => {},
  // Activities
  getActivities: (contactId: string) => {},
  // Conversations of Contact
  conversationsActiveOfContact: [],
  setConversationsActiveOfContact: (conversations: IConversation[]) => {},
  conversationsFinalizedOfContact: [],
  setConversationsFinalizedOfContact: (conversations: IConversation[]) => {},
  getConversationsOfContact: (data: ConversationsOfContact) => {},
  // Alert
  error: false,
  setError: (boolean: boolean) => {},
  errorMessage: '',
  setErrorMessage: (message: string) => {},
  handleCloseAlert: () => {},
});

const ConversationsProvider = ({ children }: ConversationsProviderProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const conversationSelected = useSelector(selectConversationSelected);
  const loadingConversation = useSelector(selectLoadingConversation);

  const loadingConversationRef = useRef(loadingConversation);

  // Internal Chat
  const [internalGroupInfo, setInternalGroupInfo] = useState<IChatMemeber[]>([]);
  const [selectMessagesMode, setSelectMessagesMode] = useState<boolean>(false);
  const [filterCondition, setFilterCondition] = useState<ChatFilter>(chatFilters[0]);
  const [selectedMessages, setSelectedMessages] = useState<IMessage[]>([]);
  const [openImageViewer, setOpenImageViewer] = useState<boolean>(false);
  const [imageViewerSrc, setImageViewerSrc] = useState<MessageImage[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openModalList, setOpenModalList] = useState(false);
  const [openActionContact, setOpenActionContact] = useState(false);
  const [conversationId, setConversationId] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>('');
  const [channel, setChannel] = useState<ImageIcon>(null);
  const [isEmailMode, setIsEmailMode] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<IContact>(null);
  const [businessInfo, setBusinessInfo] = useState<IBusiness>(null);
  const [expandedExternal, setExpandedExternal] = useState<boolean>(true);
  const [expandedInternal, setExpandedInternal] = useState<boolean>(false);
  const [originSelected, setOriginSelected] = useState<string>('');
  const [destinationSelected, setDestinationSelected] = useState<string[]>(null);
  const [assignedTo, setAssignedTo] = useState<AssignedTo>(null);
  // Conversations of contact
  const [contactSelected, setContactSelected] = useState<IContactInfo>(null);
  const [conversationsActiveOfContact, setConversationsActiveOfContact] = useState<
    IConversation[]
  >([]);
  const [conversationsFinalizedOfContact, setConversationsFinalizedOfContact] =
    useState<IConversation[]>([]);
  // Files
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  // Alert
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  // Redux
  const spaceInfo = useSelector(selectSpaceInfo);
  const user = useSelector(selectUser);

  const {
    state: {
      orderingByLastMessageDate,
      orderingByChatName,
      isLastMessageAscending,
      isChatNameAscending,
    },
    handleOrderByLastMessage,
    handleOrderByChatName,
  } = useChatsOrder();

  const getChatsQuery = (
    spaceId: string,
    condition: 'chat' | 'archived',
    sortBy: 'chatName' | 'updatedAt',
    isAscending: boolean
  ) => {
    return db.chats
      .where('spaceId')
      .equals(spaceId)
      .filter((conversation) => {
        switch (condition) {
          case 'archived':
            return conversation.archived === true;
          case 'chat':
            return conversation.archived === false;
          default:
            return false;
        }
      })
      .sortBy(sortBy)
      .then((chats) => {
        return isAscending ? chats : chats.reverse();
      });
    // .then((chats) => {
    //   return chats.sort((a, b) => {
    //     const comparison = a.chatName.localeCompare(b.chatName);
    //     return isAscending ? comparison : -comparison;
    //   });
    // });
  };

  const conversations = useLiveQuery(() => {
    // Sorting preferences
    const sortBy = orderingByLastMessageDate
      ? 'updatedAt'
      : orderingByChatName
      ? 'chatName'
      : null;
    const isAscending = orderingByLastMessageDate
      ? isLastMessageAscending
      : orderingByChatName
      ? isChatNameAscending
      : true; // Default to ascending

    return getChatsQuery(spaceInfo.id, filterCondition.value, sortBy, isAscending);
  }, [
    spaceInfo,
    user.uid,
    filterCondition.value,
    orderingByLastMessageDate,
    orderingByChatName,
    isLastMessageAscending,
    isChatNameAscending,
  ]);

  function resetConversationState() {
    setSelectMessagesMode(false);
    setSelectedMessages([]);
  }

  async function triggerOpenConversationActions(conversation: IConversation) {
    await dispatch(getMessages(conversation.id));

    if (conversation.newMessagesCount > 0) {
      dispatch(
        readConversation({
          conversationId: conversation.id,
          type: 'internal',
        })
      );
    }
  }
  function focusConversationInput() {
    const conversationInput = document.getElementById('conversation-input');

    if (conversationInput) {
      conversationInput.focus();
    }
  }

  const openConversation = async (conversation: IConversation) => {
    if (loadingConversationRef.current) return;
    try {
      dispatch(setLoadingConversation(true));

      navigate(`/a/chat/conversations`);

      resetConversationState();

      await triggerOpenConversationActions(conversation);
      dispatch(setConversationSelected(conversation));

      focusConversationInput();

      dispatch(setLoadingConversation(false));
    } catch (error) {
      console.error(error);
    }
  };

  // Files

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const documents = Array.from(event.target.files || []);

    if (documents.length === 0) {
      return;
    }

    documents.forEach((file) => {
      handleSingleFileUpload(file);
    });
  };

  const handleSingleFileUpload = async (file: File) => {
    const id = uuidv4();
    console.log;
    const { type, name, size } = file;
    const createdBy = {
      id: user.uid,
      name: user.display_name,
      imageUrl: user.imageUrl,
      isActive: user.isActive,
      userType: user.userType,
      verifiedAccount: user.verifiedAccount,
      email: user.email,
      phone: user.phone,
      status: user.status,
      sipNumber: user.sipExtensionConfig.extension,
    };
    const contactId = conversationSelected.contactInfo
      ? conversationSelected.contactInfo.id
      : user.uid;

    const data: IFile = {
      id,
      spaceId: spaceInfo.id,
      contactId,
      fileName: name,
      mimeType: type,
      size: size,
      url: '',
      createdAt: new Date(),
      //@ts-ignore
      createdBy,
    };

    setFilesToUpload((prevFiles) => [...prevFiles, data]);

    const formData = new FormData();
    formData.append('file', file);

    const URLParams: UploadURLParams = {
      module: 'chat',
      folderType: 'images',
    };

    const uploadData = {
      file: formData,
      name: id,
      id: id,
      URLParams,
    };

    console.log('uploadData', uploadData);

    const response = await dispatch(uploadMedia(uploadData));

    return response.payload as Media;
  };

  const handleAddLabel = (data: ModifyConversationLabelData) => {
    dispatch(modifyConversationLabel(data));
  };

  const getContact = useCallback(
    async (contactId: string) => {
      await dispatch(getContactInfo(contactId));
    },
    [contactInfo]
  );

  const getEndings = async () => {
    await dispatch(fetchEndings());
  };

  const getGroups = async () => {
    await dispatch(fetchGroups());
  };

  const getUsers = async () => {
    await dispatch(fetchUsers());
  };

  const getLabels = async () => {
    await dispatch(fetchLabels());
  };

  const getUserInfo = async (userId: string) => {
    await dispatch(fetchContactInfo(userId));
  };

  const getChannelList = async (channel: string) => {
    await dispatch(fetchChannels(channel));
  };

  const setInternalChat = async (data: Chat) => {
    const response = await dispatch(fetchNewChat(data));

    if (response.payload) {
      const chat = response.payload;
      const newChats = [...conversations, chat]; // Add the new chat to the start of the list

      // Update the chat state
      dispatch(setChat(chat));

      // Update the database or state with the new list
      dbWorker.postMessage({
        action: 'updateChats',
        data: newChats,
      });

      // Select the newly created chat
      openConversation(chat);

      // Expand the internal chat view
      setExpandedInternal(true);
    }
  };

  // Internal Chat
  const getInternalChats = async () => {
    const localCondition = localStorage.getItem('chatFilter');

    // Validar el valor obtenido de localStorage y establecer un valor predeterminado
    const filter: ChatRequestFilter =
      localCondition === 'chat' || localCondition === 'archived'
        ? localCondition
        : 'chat'; // valor predeterminado
    const response = await dispatch(fetchChats(filter));
    if (response.payload) {
      const chats = response.payload;

      dbWorker.postMessage({
        action: 'updateChats',
        data: chats,
      });
    }
  };

  const handleOpenInFooter = (data: OpenInFooter) => {
    dispatch(fetchOpenInFooter(data));
  };

  const handleEscapeConversation = () => {
    if (conversationSelected) {
      dispatch(setConversationSelected(null));
    }
  };

  const handleChangeAccordion = (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    state: boolean
  ) => {
    setState(!state);
  };

  const handleCloseAlert = () => {
    setError(false);
    setErrorMessage('');
  };

  const setConversation = async (data: CreateConversation): Promise<boolean> => {
    const response = await dispatch(createConversation(data));
    if (response.payload.status === 409) {
      const { name } = response.payload?.data?.contactInfo;
      setError(true);
      setErrorMessage(
        t('conversations.createConversation.error.alreadyExists', { contact: name })
      );
      return false;
    } else {
      const newCoverstation = response.payload;
      setConversationsActiveOfContact([
        ...conversationsActiveOfContact,
        newCoverstation,
      ]);
      // Inside conversation
      openConversation(newCoverstation);
      return true;
    }
  };

  // Notes
  const getNotes = async (contactId: string) => {
    await dispatch(fetchNotes(contactId));
  };

  const setNewNote = async (data: ContactNotes) => {
    await dispatch(fetchNewNote(data));
  };

  const updateNote = async (data: ContactNotes) => {
    await dispatch(fetchUpdateNote(data));
  };

  const deleteNote = async (data: ContactNotes) => {
    await dispatch(fetchDeleteNote(data));
  };

  // Files
  const getFiles = async (contactId: string) => {
    await dispatch(fetchFiles(contactId));
  };

  const setNewFile = async (data: ContactFile): Promise<IFile> => {
    const response = await dispatch(fetchNewFile(data));
    return response.payload;
  };

  const deleteFile = async (fileId: string) => {
    await dispatch(
      fetchDeleteFile({
        contactId: conversationSelected.contactInfo.id,
        fileId,
      })
    );
  };

  // Activities
  const getActivities = async (contactId: string) => {
    await dispatch(fetchActivities(contactId));
  };

  // Conversations of Contact
  const getConversationsOfContact = async (data: ConversationsOfContact) => {
    // await dispatch(fetchConversationsOfContact(data));
  };

  // Conversation modal
  const handleOpenForwardModal = () => {
    setModalTitle(t('conversations.message.actions.forward'));
    setOpenModalList(true);
  };

  useEffect(() => {
    dispatch(resetContacts());
  }, [openModalList]);

  useEffect(() => {
    return () => {
      dispatch(setConversationSelected(null));
    };
  }, []);

  useEffect(() => {
    loadingConversationRef.current = loadingConversation;
  }, [loadingConversation]);

  return (
    <conversationsContext.Provider
      value={{
        orderingByLastMessageDate,
        orderingByChatName,
        isLastMessageAscending,
        isChatNameAscending,
        handleOrderByLastMessage,
        handleOrderByChatName,
        filterCondition,
        setFilterCondition,
        handleSingleFileUpload,
        handleOpenForwardModal,
        handleEscapeConversation,
        conversations,
        openConversation,
        selectMessagesMode,
        setSelectMessagesMode,
        selectedMessages,
        setSelectedMessages,
        openImageViewer,
        setOpenImageViewer,
        imageViewerSrc,
        setImageViewerSrc,
        currentIndex,
        setCurrentIndex,
        openModalList,
        setOpenModalList,
        openActionContact,
        setOpenActionContact,
        conversationId,
        setConversationId,
        modalTitle,
        setModalTitle,
        channel,
        setChannel,
        getImage,
        isEmailMode,
        setIsEmailMode,
        getUserInfo,
        getChannelList,
        contactInfo,
        setContactInfo,
        businessInfo,
        setBusinessInfo,
        handleOpenInFooter,
        expandedExternal,
        setExpandedExternal,
        expandedInternal,
        setExpandedInternal,
        handleChangeAccordion,
        getContact,
        getEndings,
        getGroups,
        getUsers,
        getLabels,
        setConversation,
        originSelected,
        setOriginSelected,
        destinationSelected,
        setDestinationSelected,
        assignedTo,
        setAssignedTo,
        contactSelected,
        setContactSelected,
        handleAddLabel,
        // Internal Chat
        internalGroupInfo,
        setInternalGroupInfo,
        setInternalChat,
        getInternalChats,
        // Notes
        getNotes,
        setNewNote,
        updateNote,
        deleteNote,
        // Files
        getFiles,
        filesToUpload,
        setFilesToUpload,
        handleUploadFile,
        setNewFile,
        deleteFile,
        // Activities
        getActivities,
        // Conversations of Contact
        conversationsActiveOfContact,
        setConversationsActiveOfContact,
        conversationsFinalizedOfContact,
        setConversationsFinalizedOfContact,
        getConversationsOfContact,
        // Alert
        error,
        setError,
        errorMessage,
        setErrorMessage,
        handleCloseAlert,
      }}
    >
      {children}
    </conversationsContext.Provider>
  );
};

export default ConversationsProvider;
