import { ChangeEvent, useContext, useEffect, useState } from 'react';
// Context
import { conversationsContext } from '../ConversationsProvider/ConversationsProvider';
import { messagesContext } from '../MessagesProvider/MessagesProvider';
import { FilePreviewerContext } from './FilePreviewerContext';
// Types
import { FilePreviewerContextType } from './FilePreviewerContext';
import { DocumentType } from '../MessagesProvider/types/DocumentType';
import MediaFile from '../MessagesProvider/types/MediaFile';
import FileType from '../MessagesProvider/types/FileType';
// ID
import ObjectID from 'bson-objectid';
// Redux
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
import { DateTimeContext } from 'context/DateTime/DateTimeContext';
import { selectUser } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  sendMessageThunk,
  uploadMedia,
} from 'redux/features/messagesSlice/messagesSlice';
// Types
import {
  IMessage,
  MessageAck,
  MessageDirection,
  MessageType,
} from '@trii/types/dist/Common/Messages';
import { PayloadAction } from '@reduxjs/toolkit';
import { Media } from 'redux/features/messagesSlice/types/Media';
import { FormattedFormMessage } from 'redux/features/messagesSlice/types/FormattedFormMessage';
// Db
import { dbWorker } from 'db/db';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { FolderType } from 'redux/features/messagesSlice/types/UploadURLParams';

type Props = {
  children: React.ReactNode;
};

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;

const MESSAGE_TEMPLATE = {
  id: '',
  spaceId: '',
  conversationId: '',
  timestamp: new Date(),
  author: null,
  from: null,
  to: '',
  mentions: null,
  direction: MessageDirection.OUT,
  ackLogs: [],
  ack: MessageAck.ACK_PENDING,
  forwarded: false,
  remoteDeleted: false,
  type: null,
  context: '',
  text: null,
  audio: null,
  contacts: [],
  messageReference: null,
  deleted: false,
  isLoaded: false,
  documents: null,
  isHighlighted: false,
  updatedAt: new Date(),
  updatedBy: null,
};

const FilePreviewerProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const {
    setFileSelectorMode,
    files,
    documents,
    messageLoading,
    setMessageLoading,
  } = useContext(messagesContext);
  const { datetime } = useContext(DateTimeContext);

  const conversationSelected = useSelector(selectConversationSelected);
  const userInfo = useSelector(selectUser);
  const spaceInfo = useSelector(selectSpaceInfo);

  // Files
  const [filesCopy, setFilesCopy] = useState<MediaFile[]>([]);
  const [selectedFile, setSelectedFile] = useState<MediaFile | null>(null);

  // Documents
  const [documentsCopy, setDocumentsCopy] = useState<DocumentType[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null
  );
  //
  const [showActions, setShowActions] = useState<boolean>(false);

  const getFileType = (type: string): FileType => {
    if (type) {
      if (type.startsWith('text/') || type.startsWith('application/')) {
        return 'text';
      } else if (type.startsWith('image/')) {
        return 'image';
      } else if (type.startsWith('video/')) {
        return 'video';
      } else {
        return 'other';
      }
    }
  };

  const handleFilesChange = () => {
    // In case that copy updates removing a file
    if (filesCopy.length > files.length) {
      const filesCopyFiltered = filesCopy.filter((fileCopy) =>
        files.some((file) => file.id === fileCopy.id)
      );

      setFilesCopy(filesCopyFiltered);
      setSelectedFile(filesCopyFiltered[0]);
    }

    // In case that copy updates adding a file
    if (filesCopy.length < files.length) {
      const newFiles = files.filter(
        (file) => !filesCopy.some((fileCopy) => fileCopy.id === file.id)
      );

      const newFilesCopy = [...filesCopy, ...newFiles];

      setFilesCopy((prevFiles) => [...prevFiles, ...newFiles]);
      setSelectedFile(newFilesCopy[0]);
    }
  };

  const handleFileRemove = (fileId: string) => {
    const newFiles = filesCopy.filter((file) => file.id !== fileId);

    if (newFiles.length === 0) {
      setFilesCopy([]);
      setFileSelectorMode(null);
      setSelectedFile(null);

      return;
    }

    if (fileId === selectedFile?.id) {
      setSelectedFile(newFiles[0]);
    }

    setFilesCopy(newFiles);
  };

  const handleFileSelect = (fileId: string) => {
    const file = filesCopy.find((file) => file.id === fileId);

    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFileMsgChange = (
    event: ChangeEvent<HTMLInputElement>,
    fileId: string
  ) => {
    const newFiles = filesCopy.map((file) => {
      if (file.id === fileId) {
        return Object.assign(file, { caption: event.target.value });
      }

      return file;
    });

    setFilesCopy(newFiles);
  };

  const endDocumentSelectorMode = () => {
    setDocumentsCopy([]);
    setSelectedDocument(null);
    setFileSelectorMode(false);
  };

  const handleDocumentChange = () => {
    // In case that copy updates removing a document
    if (documentsCopy.length > documents.length) {
      const documentsCopyFiltered = documentsCopy.filter((documentCopy) =>
        documents.some((document) => document.id === documentCopy.id)
      );

      setDocumentsCopy(documentsCopyFiltered);
      setSelectedDocument(documentsCopyFiltered[0]);
    }

    // In case that copy updates adding a document
    if (documentsCopy.length < documents.length) {
      const newDocuments = documents.filter(
        (document) =>
          !documentsCopy.some((documentCopy) => documentCopy.id === document.id)
      );

      const newDocumentsCopy = [...documentsCopy, ...newDocuments];

      setDocumentsCopy((prevDocuments) => [...prevDocuments, ...newDocuments]);
      setSelectedDocument(newDocumentsCopy[0]);
    }
  };

  const handleDocumentRemove = (documentId: string) => {
    const newDocuments = documentsCopy.filter((file) => file.id !== documentId);

    if (newDocuments.length === 0) {
      endDocumentSelectorMode();
      return;
    }

    if (documentId === selectedFile?.id) {
      setSelectedDocument(newDocuments[0]);
    }

    setDocumentsCopy(newDocuments);
  };

  const handleDocumentSelect = (documentId: string) => {
    const document = documentsCopy.find((document) => document.id === documentId);

    if (document) {
      setSelectedDocument(document);
    }
  };

  function getFolderType(mimeType: string): FolderType {
    if (mimeType.startsWith('image/')) {
      return 'images';
    } else if (mimeType.startsWith('video/')) {
      return 'videos';
    } else if (mimeType.startsWith('audio/')) {
      return 'audios';
    } else if (mimeType.startsWith('application/')) {
      return 'documents';
    } else {
      return 'documents';
    }
  }

  const handleUpload = async (
    files: MediaFile[] | DocumentType[],
    messageId: string
  ): Promise<MediaFile[] | DocumentType[]> => {
    const newFiles = [...files] as MediaFile[] | DocumentType[];

    files.map((file: MediaFile | DocumentType) => {
      if (file?.id && !messageLoading.includes(messageId)) {
        setMessageLoading([...messageLoading, messageId]);
      }
    });

    for (const data of files) {
      if (data && data.file) {
        const { file, filename, id, mimeType } = data;
        const formData = new FormData();

        formData.append('file', file, filename);

        const result = (await dispatch(
          uploadMedia({
            file: formData,
            name: filename,
            id,
            URLParams: {
              module: 'chat',
              folderType: getFolderType(mimeType),
            },
          })
        )) as PayloadAction<Media>;
        if (result && result.payload) {
          const { url, id } = result.payload;
          newFiles.forEach((file: MediaFile | DocumentType) => {
            if (file.id === id) {
              file.url = url;
            }
          });
        }
      }
    }

    setMessageLoading(messageLoading.filter((msg) => msg !== messageId));

    return newFiles;
  };

  const verifyAllLoaded = (files: MediaFile[]) => {
    return files.every((file) => {
      if (file) {
        const splitBase64 = file.url.split(',')[0];
        const isBase64 = REGEX_BASE64.test(splitBase64);
        return !isBase64;
      }
    });
  };

  const sendMediaMessage = async (files: MediaFile[], conversationId: string) => {
    const messageId = ObjectID().toString();

    // Helper function to filter media files by type
    const filterMediaByType = (type: string) =>
      files.filter((file) => file && getFileType(file.mimeType) === type);

    // Filter images and videos
    const images = filterMediaByType('image');
    const videos = filterMediaByType('video').map((file) => ({
      ...file,
      animated: false, // Add specific property for videos
    }));

    // Create message object template
    const mediaMessage = {
      ...MESSAGE_TEMPLATE,
      contactId: conversationSelected.contactInfo?.id,
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      channelInfo: conversationSelected.channelInfo,
      shardKey: `${spaceInfo.id}_chat_${conversationId}`,
      id: messageId,
      spaceId: spaceInfo.id,
      conversationId,
      timestamp: datetime.getDateTime(),
      userId: userInfo.uid,
      type: MessageType.CHAT,
      images,
      videos,
    };

    await dbWorker.postMessage({
      action: 'setMessage',
      data: mediaMessage,
    });

    // Upload images and videos
    const uploadMedia = async (media: MediaFile[]) =>
      media.length > 0 ? await handleUpload(media, messageId) : [];

    const [uploadedImages, uploadedVideos] = await Promise.all([
      uploadMedia(images),
      uploadMedia(videos),
    ]);

    // Verify all media is uploaded
    const allImagesLoaded = verifyAllLoaded(images);
    const allVideosLoaded = verifyAllLoaded(videos);

    // Dispatch message based on upload results
    const dispatchMultimediaMessage = async (
      images: MediaFile[],
      videos: MediaFile[]
    ) => {
      const newMultimediaMessage = {
        ...mediaMessage,
        images,
        videos,
        isLoaded: allImagesLoaded && allVideosLoaded,
      };
      const response = await dispatch(sendMessageThunk(newMultimediaMessage));

      await dbWorker.postMessage({
        action: 'setMessage',
        data: response.payload,
      });
    };

    if (uploadedImages.length > 0 || uploadedVideos.length > 0) {
      await dispatchMultimediaMessage(uploadedImages, uploadedVideos);
    }
  };

  const sendDocuments = async (
    documents: DocumentType[],
    conversationId: string
  ) => {
    const messageId = ObjectID().toString();
    const documentMessage = {
      ...MESSAGE_TEMPLATE,
      id: messageId,
      channelInfo: conversationSelected.channelInfo,
      shardKey: `${spaceInfo.id}_chat_${conversationId}`,
      spaceId: spaceInfo.id,
      conversationId,
      contactId: conversationSelected.contactInfo?.id,
      timestamp: datetime.getDateTime(),
      userId: userInfo.uid,
      from: conversationSelected.contactInfo?.id,
      to: conversationSelected.remoteAddress,
      type: MessageType.CHAT,
      documents,
    };

    await dbWorker.postMessage({
      action: 'setMessage',
      data: documentMessage,
    });

    const uploadedDocuments = (await handleUpload(
      documents,
      messageId
    )) as DocumentType[];

    const verifyAllLoaded = uploadedDocuments.every((document) => {
      if (document) {
        return document.url !== '';
      }
    });

    if (uploadedDocuments && verifyAllLoaded) {
      const newDocumentsMessage = {
        ...documentMessage,
        documents: uploadedDocuments,
        isLoaded: verifyAllLoaded,
      };

      const response = await dispatch(sendMessageThunk(newDocumentsMessage));

      await dbWorker.postMessage({
        action: 'setMessage',
        data: response.payload,
      });
    }
  };

  useEffect(() => {
    handleFilesChange();
  }, [files]);

  useEffect(() => {
    handleDocumentChange();
  }, [documents]);

  const value: FilePreviewerContextType = {
    filesCopy,
    selectedFile,
    getFileType,
    selectedDocument,
    handleFileMsgChange,
    handleFileRemove,
    handleFileSelect,
    documentsCopy,
    handleDocumentRemove,
    handleDocumentSelect,
    endDocumentSelectorMode,
    sendMediaMessage,
    sendDocuments,
    showActions,
    setShowActions,
    handleUpload,
  };

  return (
    <FilePreviewerContext.Provider value={value}>
      {children}
    </FilePreviewerContext.Provider>
  );
};

export default FilePreviewerProvider;
