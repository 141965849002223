// Types
import { TemplateHeader } from '@trii/types/dist/Common/Messages';
import { MessageHeader, MessageHeaderType } from '@trii/types/dist/Common/Messages';
// Components/ui
import {
  Document,
  Image,
} from 'features/Views/Conversations/components/components/Conversation/components/Body/components/MessageItem/components/TextType/components';
export function getHeaderContent(
  header: MessageHeader | TemplateHeader
): React.ReactNode {
  if (!header) return null;

  switch (header.type) {
    case MessageHeaderType.TEXT:
      return header.text;
    case MessageHeaderType.IMAGE:
      return (
        <Image
          messageId={''}
          files={[{ ...header, id: 'id', caption: '' }]}
          // isLoaded={true}
          type="file"
          templateFileType={header.type}
        />
      );
    case MessageHeaderType.VIDEO:
      return (
        <Image
          messageId={''}
          files={[{ ...header, id: '', caption: '' }]}
          // isLoaded={true}
          type="file"
          templateFileType={header.type}
        />
      );
    case MessageHeaderType.FILE:
      return (
        <Document
          messageId=""
          documents={[{ ...header, id: '', caption: 'document' }]}
        />
      );
    case MessageHeaderType.LOCATION:
      return `${header.locationName} - ${header.locationAddress}`;
    default:
      return null;
  }
}
