import { useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton } from '@mui/material';
// Icons
import { GroupAdd } from '@mui/icons-material';
import { Chats, Filter, OrderBy } from './components';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Worker
import { dbWorker } from 'db/db';
// Types
import { IConversation } from '@trii/types/dist/Conversations';

const InternalChat = () => {
  const { t } = useTranslation();

  const { subscribeEvent, unsubscribeEvent } = useContext(socketContext);
  const { setExpandedInternal, setModalTitle, setOpenModalList } =
    useContext(conversationsContext);

  const handleNewInternalChat = () => {
    setModalTitle(t('conversations.internalChat.newInternalChat'));
    setOpenModalList(true);
  };

  useEffect(() => {
    subscribeEvent('chat_update', async (data: IConversation) => {
      console.log('chat_update', data);

      dbWorker.postMessage({
        action: 'setChat',
        data: data,
      });
    });

    return () => {
      unsubscribeEvent('chat_update');
    };
  }, []);

  // Set the initial state of the accordion according to the local storage
  useEffect(() => {
    const expanded = localStorage.getItem('expandedInternal');
    if (expanded) {
      setExpandedInternal(expanded === 'true');
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        height: 'calc(100% - 3rem)',
        '&:before': {
          display: 'none',
        },
        transition: 'max-height 0.5s ease-in-out',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        sx={{
          maxHeight: '3rem',
          height: '100%',
        }}
        alignItems="center"
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
      >
        <Box
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Filter />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
            }}
          >
            <IconButton onClick={handleNewInternalChat}>
              <GroupAdd sx={{ color: 'text.disabled' }} />
            </IconButton>
            <OrderBy />
          </Box>
        </Box>
      </Box>
      <Chats />
    </Box>
  );
};

export default InternalChat;
