import { styled } from '@mui/material/styles';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { Audio, Document, Text } from './components';
// Icons
import { Close } from '@mui/icons-material';
import {
  IMessage,
  MessageImage,
  MessageVideo,
} from '@trii/types/dist/Common/Messages';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
// Slice
import { selectUser } from 'redux/features/userSlice/userSlice';
interface Props {
  messageReply: IMessage;
  isReplying?: boolean;
  handleClose?: () => void;
  setRepliesInitializing?: Dispatch<SetStateAction<string[]>>;
}

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}));

const MessageBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0.5rem 0',
  padding: '5px 0px 5px 5px',
  width: '100%',
  height: 'max-content',
  maxHeight: '5rem',
  overflow: 'hidden',
  userSelect: 'none',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'max-content',
  padding: '0 5px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-word',
}));

const RenderMedia = ({
  images,
  videos,
}: {
  images?: MessageImage[];
  videos?: MessageVideo[];
}) => {
  const media = images?.[0] || videos?.[0];
  if (!media) return null;

  return (
    <CardMedia
      component={images ? 'img' : 'video'}
      src={media.url}
      sx={{
        width: 'auto',
        height: '5rem',
        objectFit: 'contain',
        borderRadius: '5px',
      }}
    />
  );
};

const ReplyMessage = ({
  messageReply,
  isReplying = false,
  handleClose = () => {},
  setRepliesInitializing,
}: Props) => {
  const { t } = useTranslation();
  const { handleNavigateToRepliedMessage } = useContext(messagesContext);
  const { getUserInfo } = useUserInfo();
  const [userName, setUserName] = useState<string>('');
  const user = useSelector(selectUser);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        if (user && user.uid === messageReply.userId) {
          setUserName(t('conversations.message.your'));
        } else {
          const newUser = await getUserInfo(messageReply.userId);
          setUserName(newUser?.name || '');
        }
      } catch (error) {
        setUserName(t('conversations.message.unknownUser'));
      }

      if (setRepliesInitializing)
        setRepliesInitializing((prev) =>
          prev.filter((id) => id !== messageReply.id)
        );
    };
    fetchUserName();
  }, [messageReply]);

  return (
    <Container>
      <MessageBox onClick={() => handleNavigateToRepliedMessage(messageReply.id)}>
        <ContentBox>
          <StyledTypography>{userName}</StyledTypography>
          {messageReply?.text && <Text text={messageReply.text.body} />}
          {messageReply?.audio && <Audio messageId={messageReply.id} />}
          {messageReply?.documents?.length > 0 && (
            <Document documents={messageReply.documents} />
          )}
        </ContentBox>
        <RenderMedia images={messageReply?.images} videos={messageReply?.videos} />
      </MessageBox>
      {isReplying && (
        <IconButton onClick={handleClose} sx={{ color: 'text.disabled' }}>
          <Close />
        </IconButton>
      )}
    </Container>
  );
};

export default ReplyMessage;
