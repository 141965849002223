import { useContext } from 'react';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Components/ui
import { Box } from '@mui/material';
import { ChatItem } from './components';

const Chats = () => {
  const { conversations } = useContext(conversationsContext);

  const Chats = conversations?.map((chat) => <ChatItem key={chat.id} chat={chat} />);

  return (
    <Box
      width="100%"
      sx={{
        overflowY: 'auto',
        height: '100%',
        position: 'relative',
      }}
    >
      {Chats}
    </Box>
  );
};

export default Chats;
