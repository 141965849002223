import { useContext } from 'react';
// Context
import { FilePreviewerContext } from 'features/Views/Conversations/context/FilePreviewerProvider/FilePreviewerContext';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
// ID
import { v4 as uuidv4 } from 'uuid';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';

const SendButton = () => {
  const { endFileSelectorMode } = useContext(messagesContext);
  const {
    filesCopy,
    documentsCopy,
    endDocumentSelectorMode,
    sendMediaMessage,
    sendDocuments,
    showActions,
    setShowActions,
  } = useContext(FilePreviewerContext);
  const conversationSelected = useSelector(selectConversationSelected);

  const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64 = reader.result;
        resolve(base64);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSendImage = async () => {
    const imagesFormat = await Promise.all(
      filesCopy.map(async (data) => {
        try {
          if (data) {
            const { filename, mimeType, caption, file, previewUrl } = data;
            const base64 = file ? await getBase64(data.file) : data.url;
            const id = uuidv4().toString();
            return {
              filename,
              url: base64.toString(),
              mimeType,
              caption,
              id,
              file,
              previewUrl,
            };
          }
        } catch (error) {
          console.log('Error:', error);
          return null;
        }
      })
    );

    imagesFormat.forEach((imageFormat) => {
      sendMediaMessage([imageFormat], conversationSelected.id);
    });

    if (showActions) setShowActions(false);
    endFileSelectorMode();
  };

  const handleSendDocument = async () => {
    const documentsFormat = documentsCopy.map((data) => {
      const id = uuidv4().toString();
      const { filename, mimeType, file } = data;
      return {
        filename,
        url: '',
        mimeType,
        id,
        file,
        caption: '',
      };
    });

    documentsFormat.forEach((documentFormat) => {
      sendDocuments([documentFormat], conversationSelected.id);
    });

    endDocumentSelectorMode();
  };

  const handleSend = () => {
    if (filesCopy.length > 0) {
      handleSendImage();
    } else if (documentsCopy.length > 0) {
      handleSendDocument();
    }
  };

  return (
    <IconButton
      disableRipple
      sx={{
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        display: 'flex',
      }}
      size="large"
      onClick={handleSend}
    >
      <SendIcon />
    </IconButton>
  );
};

export default SendButton;
