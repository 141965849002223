import { useState } from 'react';
// Context
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, CircularProgress, Fab, IconButton, Typography } from '@mui/material';
// Icons
import { Clear, FileUpload, Replay } from '@mui/icons-material';

interface LoaderProps {
  handleTryAgain: () => void;
  isLoading: boolean;
  messageId: string;
  // isLoaded: boolean;
}

const Loader = ({
  handleTryAgain,
  isLoading,
}: // isLoaded,
LoaderProps) => {
  const [cancel, setCancel] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleMouseOver = () => {
    setCancel(true);
  };

  const handleMouseOut = () => {
    setCancel(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        {!isLoading ? (
          <IconButton
            onClick={() => handleTryAgain()}
            sx={{
              backgroundColor: 'rgba(0,0,0,0.6)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.8)',
              },
              borderRadius: '10rem',
            }}
          >
            <Replay sx={{ color: 'white' }} />
            <Typography
              variant="caption"
              sx={{
                color: 'white',
                marginLeft: '0.5rem',
                fontWeight: 'bold',
                lineHeight: '0',
              }}
            >
              {t('conversations.message.tryAgain')}
            </Typography>
          </IconButton>
        ) : (
          <>
            <Fab
              aria-label="action"
              sx={{
                width: 35,
                height: 35,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                },
                position: 'absolute',
                zIndex: 2,
              }}
              onMouseOver={() => handleMouseOver()}
              onMouseOut={() => handleMouseOut()}
            >
              {isLoading && cancel ? (
                <IconButton>
                  <Clear sx={{ color: 'white' }} />
                </IconButton>
              ) : (
                <IconButton>
                  <FileUpload sx={{ color: 'white' }} />
                </IconButton>
              )}
            </Fab>
            <CircularProgress
              size={45}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zid: 1,
                zIndex: 1,
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Loader;
