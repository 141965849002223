import React, { createContext, useEffect, useState } from 'react';
// Redux
import { initRequestData } from 'redux/functions/initRequestData';
// Socket.IO
import { io, Socket } from 'socket.io-client';
// Types
import SocketContextType from './types/SocketContextType';
import SocketConnectionStatus from './types/SocketConnectionStatus';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { selectSpaceInfo } from 'redux/features/spaceSlice/spaceSlice';
// Db
import { dbWorker } from 'db/db';
import { fetchChats } from 'redux/features/conversationsSlice/conversationsSlice';
import { ChatRequestFilter } from 'redux/features/conversationsSlice/types/ChatRequestFilter';

export const socketContext = createContext<SocketContextType>({
  socketConnection: null,
  connectionStatus: 'offline',
  subscribeEvent: () => {},
  unsubscribeEvent: () => {},
  emitEvent: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const SocketProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const spaceInfo = useSelector(selectSpaceInfo);

  const [token, setToken] = useState<string>('');
  const [socketConnection, setSocketConnection] = useState<Socket | null>(null);
  const [connectionStatus, setConnectionStatus] =
    useState<SocketConnectionStatus>('offline');

  const getToken = async () => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    return jwtToken;
  };

  // Event functions
  const subscribeEvent = (eventName: string, callback: (...args: any[]) => void) => {
    if (socketConnection) {
      console.log('connect to: ', eventName);
      socketConnection.on(eventName, callback);
    }
  };
  const unsubscribeEvent = (eventName: string) => {
    if (socketConnection) {
      socketConnection.off(eventName);
    }
  };
  const emitEvent = (eventName: string, ...args: any[]) => {
    if (socketConnection) {
      socketConnection.emit(eventName, ...args);
    }
  };

  useEffect(() => {
    if (token) return;

    const initializeToken = async () => {
      const jwtToken = await getToken();
      setToken(jwtToken);
    };

    initializeToken();
  }, []);

  useEffect(() => {
    if (socketConnection) return;

    if (!token) return;

    if (typeof window !== 'undefined' && (window as any).socketConnection) {
      const socket = (window as any).socketConnection;

      setSocketConnection(socket);

      socket.on('connect', async () => {
        console.log('Conversations Connected to Socket.IO server');
        setConnectionStatus('online');

        if (location.pathname.includes('/a/chat/conversations/')) {
          const localCondition = localStorage.getItem('chatFilter');

          // Validar el valor obtenido de localStorage y establecer un valor predeterminado
          const filter: ChatRequestFilter =
            localCondition === 'chat' || localCondition === 'archived'
              ? localCondition
              : 'chat'; // valor predeterminado

          await dispatch(fetchChats(filter));
        }
      });
      socket.on('disconnect', () => {
        console.log('Conversations Disconnected from Socket.IO server');
      });
    }
  }, [dispatch, token]);

  return (
    <socketContext.Provider
      value={{
        subscribeEvent,
        unsubscribeEvent,
        emitEvent,
        socketConnection,
        connectionStatus,
      }}
    >
      {children}
    </socketContext.Provider>
  );
};

export default SocketProvider;
