import { useState, useContext, useEffect } from 'react';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import {
  selectUser,
  selectUsers,
  selectUsersStatus,
} from 'redux/features/userSlice/userSlice';
// Translate
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, CircularProgress, List, Tab, Tabs, TextField } from '@mui/material';
// Types
import { UserInfo } from '@trii/types/dist/Users';
import { ChatType } from '@trii/types/dist/Conversations';
// Components
import { ChatItem } from './components';
import { selectChats } from 'redux/features/conversationsSlice/conversationsSlice';

const USER_TAB = 'userId';
const GROUP_TAB = 'groupId';

const NewInternalChat = () => {
  const { setOpenModalList, setModalTitle, setInternalChat } =
    useContext(conversationsContext);
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectUser);
  const usersStatus = useSelector(selectUsersStatus);
  const chatCreated = useSelector(selectChats)
  const isLoadingUsers = usersStatus === 'loading';
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(USER_TAB);
  const [userSelected, setUserSelected] = useState<UserInfo>(null);
  const [groupSelected, setGroupSelected] = useState<UserInfo[]>([]);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const [userList, setUserList] = useState<UserInfo[]>([]);
  const [groupName, setGroupName] = useState<string>(''); // Nueva variable para el nombre del grupo

  const handleClose = () => {
    setOpenModalList(false);
    setModalTitle('');
  };

  const handleChange = (tab: string) => {
    setValue(tab);
    setUserSelected(null);
    setGroupSelected([]);
  };

  const handleSelect = (newUser: UserInfo) => {
    if (value === USER_TAB) {
      setUserSelected(newUser);
    } else {
      if (groupSelected && groupSelected.length > 0) {
        const getIds = groupSelected.map((user) => user.id);
        if (getIds.includes(newUser.id)) {
          const filtered = groupSelected.filter((user) => newUser.id !== user.id);
          setGroupSelected(filtered);
        } else {
          setGroupSelected([...groupSelected, newUser]);
        }
      } else {
        setGroupSelected([newUser]);
      }
    }
  };

  const handleChecked = (id: string) => {
    if (value === USER_TAB) {
      if (userSelected && userSelected.id === id) {
        return true;
      }
      return false;
    } else {
      if (groupSelected && groupSelected.length > 0) {
        const getIds = groupSelected.map((user: UserInfo) => user.id);
        if (getIds.includes(id)) {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  const handleCreate = () => {
    const data = {
      type: value === GROUP_TAB ? ChatType.GROUP : ChatType.DIRECT,
      members: value === USER_TAB ? [userSelected.id] : groupSelected.map((user: UserInfo) => user.id),
      groupName: value === GROUP_TAB ? groupName : undefined, // Incluye el nombre del grupo si es el tab de grupo
    };
    
    setInternalChat(data);
    setOpenModalList(false);
    setModalTitle('');
    
  };

  useEffect(() => {
    if (users && users.length > 0) {
      const filterUsers = users.filter((user) => user.id !== currentUser.uid);
      setUserList(filterUsers);
    }
  }, [users]);

  useEffect(() => {
    if (userSelected || (groupSelected && groupSelected.length > 0)) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  }, [value, userSelected, groupSelected]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1em',
        height: 'calc(100% - 3rem)',
        overflow: 'auto',
      }}
    >
      <Tabs
        value={value}
        onChange={(e, tab) => handleChange(tab)}
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{
          height: '3rem',
        }}
      >
        <Tab label={t('global.user')} value={USER_TAB} />
        <Tab label={t('settingsView.groups.group')} value={GROUP_TAB} />
      </Tabs>
      
      {value === GROUP_TAB && (
        <TextField
          label={t('settingsView.groups.groupName')}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ marginY: '1em' }}
          InputLabelProps={{
            shrink: true,
          }}
          size='small'
        />
      )}
      
      {isLoadingUsers ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '1em',
          }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 6rem)',
            overflow: 'auto',
          }}
        >
          {userList &&
            userList.length > 0 &&
            userList.map((data: UserInfo, i: number) => (
              <ChatItem
                key={i}
                data={data}
                handleChecked={handleChecked}
                handleSelect={handleSelect}
                showCheckbox={value === USER_TAB}
              />
            ))}
        </List>
      )}
      
      <Box
        sx={{
          width: '100%',
          height: '3rem',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
          position: 'sticky',
          bottom: 0,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          onClick={handleClose}
        >
          {t('global.close')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: '.5rem',
          }}
          disabled={!canCreate}
          onClick={handleCreate}
        >
          {t('global.create')}
        </Button>
      </Box>
    </Box>
  );
};

export default NewInternalChat;
