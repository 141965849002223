import { useState, useContext } from "react";
// Context
import { messagesContext } from "features/Views/Conversations/context/MessagesProvider/MessagesProvider";
import { conversationsContext } from "features/Views/Conversations/context/ConversationsProvider/ConversationsProvider";
// Translate
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, IconButton, Menu, useTheme } from "@mui/material";
// Icons
import {
  AddComment,
  ConfirmationNumber,
  KeyboardArrowDown,
  Reply,
  ReplyAll,
  Star,
} from "@mui/icons-material";
// Components
import { Item } from "./components";
// Types
import { IMessage, MessageDirection } from "@trii/types/dist/Common/Messages";
import db, { dbWorker } from "db/db";

type Props = {
  over: boolean;
  setOver: (value: boolean) => void;
  message: IMessage;
  showReply: boolean;
  direction: MessageDirection;
};

const MessageActions = ({
  over,
  setOver,
  message,
  showReply,
  direction,
}: Props) => {
  const { highlightMessage, setMessage, setIsReplying, setMessageReply } =
    useContext(messagesContext);
  const { setModalTitle, setOpenModalList, handleOpenForwardModal } =
    useContext(conversationsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOver(false);
    setAnchorEl(null);
  };

  const handleReply = () => {
    setMessageReply(message);
    setIsReplying(true);
  };

  const handleAddComment = () => {
    setModalTitle(t("conversations.message.actions.addComment"));
    setOpenModalList(true);
    setMessage(message);
  };

  const handleResend = () => {
    handleOpenForwardModal();
    setMessage(message);
  };

  const handleHighlight = async () => {
    const { id: messageId, shardKey } = message;
    const data = {
      messageId,
      shardKey,
      isHighlighted: !message.isHighlighted,
    };
    const response = await highlightMessage(data);
    await dbWorker.postMessage({
      action: "updateMessage",
      data: response,
    });

    handleClose();
  };

  const theme = useTheme();

  return (
    <Box
      display={`${over || anchorEl ? "flex" : "none"}`}
      position="absolute"
      right="0rem"
      top="0"
      sx={{
        color: "text.disabled",
        "& button:hover": {
          //@ts-ignore
          backgroundColor:
            direction === MessageDirection.IN
              ? theme.palette.mode === "light"
                ? theme.palette.background.default
                : //@ts-ignore
                  theme.palette.background.dropdownMenu
              : //@ts-ignore
                theme.palette.messageBox,
        },
      }}
    >
      <IconButton
        onClick={handleOpen}
        aria-describedby={"popupPrincipal"}
        size="small"
       
      >
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={openMenu}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor:
              //@ts-ignore
              (theme) => theme.palette.background.panel,
          },
        }}
      >
        {showReply && (
          <Item
            title={t("conversations.message.actions.reply")}
            icon={<Reply fontSize="small" />}
            onClick={handleReply}
          />
        )}
        <Item
          title={t("conversations.message.actions.forward")}
          icon={
            <Reply fontSize="small" sx={{ transform: "rotateY(180deg)" }} />
          }
          onClick={handleResend}
        />
        {/* <Item
          title={t("conversations.message.actions.createTicket")}
          icon={<ConfirmationNumber fontSize="small" />}
          onClick={() => console.log("createTicket")}
        />
        <Item
          title={t(
            `conversations.message.actions.${
              message.isHighlighted
                ? "doNotHighlightMessage"
                : "highlightMessage"
            }`
          )}
          icon={<Star fontSize="small" />}
          onClick={handleHighlight}
        />
        <Item
          title={t("conversations.message.actions.addComment")}
          icon={<AddComment fontSize="small" />}
          onClick={handleAddComment}
        /> */}
      </Menu>
    </Box>
  );
};

export default MessageActions;
